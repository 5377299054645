.diaporama {
    @media (max-width: 767px) {
        height: 100vw;
    }

    &-wrapper {

    }

    &-slide {

    }

    &-img {

    }

    &-blockTitle {

    }

    &-title {

    }

    &-buttonNext, &-buttonPrev {

    }

    &-pagination {

    }
}
